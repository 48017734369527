import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';

export const InputColor = styled.label`
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

export const InputWrapper = styled.div`
  width: 3rem;
  height: 3rem;
  flex-shrink: 0;
  border: 1px solid ${Colors.outline};
  border-radius: 0.4rem;
  margin-right: 0.8rem;
`;

export const ColorCode = styled.div`
  text-transform: uppercase;
  ${({ theme }) => theme.typography.text.normal};
`;

export const ColorPickerWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 990;
`;
